import * as Sentry from "@sentry/gatsby";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://ef026ba4758ee3bc78417bf5359b8983@o1070970.ingest.us.sentry.io/4508183572054016",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],

    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
