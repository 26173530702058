/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// // You can delete this file if you're not using it

require('./src/sass/base/_base.scss');
require('./src/sass/base/_typography.scss');
